<template>
<div class="to-be-incentive-manage" v-loading="pageLoading">
  <el-form :model="form" class="row-center-start tbim-form" ref="formRef">
    <el-form-item label="自设备激活" prop="days">
      <el-input v-model.trim="form.days" @input="numberInput($event, 'days')"></el-input>
    </el-form-item>
    <el-form-item label="日起完成" class="special-item" prop="cnt_orders">
      <el-input v-model.trim="form.cnt_orders" @input="numberInput($event, 'cnt_orders')"></el-input>
      单
    </el-form-item>
    <el-form-item label="设备类型" prop="device_type_id">
      <el-select v-model="form.device_type_id">
        <el-option placeholder="请选择" v-for="(item, index) in deviceOption" :key="index" :label="item.txt_type_name" :value="item.t_device_type_id" />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button :disabled="searchBtnDisabled" type="primary" @click="getList()">搜索</el-button>
      <el-button type="info" @click="reset()">重置</el-button>
    </el-form-item>
  </el-form>
  <el-table :data="table.tableData" v-loading="table.tabelLoading" @selection-change="handleSelectionChange">
    <el-table-column type="selection" width="55" />
    <el-table-column prop="txt_province" label="省份" align="center" />
    <el-table-column prop="txt_city" label="城市" align="center" />
    <el-table-column prop="txt_salesman_name" label="业务员姓名" align="center" />
    <el-table-column prop="cnt_orders" label="达标单数" align="center" />
  </el-table>
  <div class="tbim-bottom-hint-parts column-start-center">
    <div>当前营销账户余额￥{{bonus.balance}}</div>
    <div class="tbimbhp-bottom row-center-center">
      <div class="row-center-center">发放奖励<el-input min="0" size="small" @input="rewardInput($event)" v-model.trim="bonus.reward"></el-input>/人</div>
      <el-button :disabled="sendBtnDisabled" type="primary" size="small" @click="sendBonus()">发送</el-button>
    </div>
  </div>
</div>
</template>

<script>
// 奖励发放-组件
import { useStore } from 'vuex';
import { reactive, toRefs, ref, onMounted, computed } from 'vue';
import { ElMessage, FormInstance, ElMessageBox } from 'element-plus';
import { getDataByCondition, getDeviceType, getQueryBalance, goPayBonus } from "api/apis.js";
export default {
  setup() {
    const store = useStore();
    let moment = require("moment");
    const state = reactive({
      formRef: ref(FormInstance),
      form: {
        days: null,
        cnt_orders: null,
        device_type_id: null
      },
      table: {
        tableData: [],
        tabelLoading: false,
        selected: []
      },
      deviceOption: [],
      reward: null,
      bonus: {
        reward: null,
        balance: 0
      },
      pageLoading: false,
      // 搜索按钮可用条件
      searchBtnDisabled: computed(() => (state.form.days || "") == "" || (state.form.cnt_orders || "") == ""),
      // 奖金发放按钮可用条件
      sendBtnDisabled: computed(() => {
        let result = true;
        // 列表有数据
        if (state.table.selected.length != 0) {
          // 发放奖励 input 框内有数据 且不为 0
          if ((state.bonus.reward || "") != "" && state.bonus.reward != 0) {
            // 发放的总钱数
            let amountReward = parseFloat(state.bonus.reward) * state.table.selected.length;
            // 发放的总钱数要小于或等于账户总余额
            if (amountReward <= state.bonus.balance) {
              result = false;
            }
          }
        }
        return result;
      })
    });

    onMounted(() => {
      getDevices();
      getBalanceAmount();
    });

    const numberInput = (e, key) => {
      e = e.replace(/[^\d]/g, ""); // 清除"数字"以外的字符
      e = e.replace(/^(0{1,})(\d{1,}.*)/, '$2'); // 去除开头的多余的0
      state.form[key] = e;
    };

    // 金额校验
    const rewardInput = (e) => {
      if (state.table.selected.length == 0) {
        ElMessage({
          type: "warning",
          message: "请至少选择一个业务员",
          duration: 2000
        })
        state.bonus.reward = null;
      } else {
        // 清除"数字"和"."以外的字符
        e = e.replace(/[^\d.]/g, "");
        // 验证第一个字符是数字
        e = e.replace(/^\./g, "");
        // 只保留第一个, 清除多余的
        e = e.replace(/\.{2,}/g, ".");
        e = e.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        e = e.replace(/^(0{1,})(\d{1,}.*)/, '$2'); // 去除开头的多余的0
        state.bonus.reward = e;
      }
    };

    // 表格选择事件
    const handleSelectionChange = (value) => {
      state.table.selected = value;
    };

    // 奖金发放
    const sendBonus = () => {
      ElMessageBox.confirm(state.bonus.reward + "元/人奖励将发放，该操作不可逆，确认继续？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        state.pageLoading = true;
        let params = {
          days: state.form.days,
          orders: state.form.cnt_orders,
          rewards: state.bonus.reward,
          txt_creator: store.getters.userInfo.name || "系统用户",
          dt_create: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          data: state.table.selected.map(t => {
            return {
              t_agent_id: t.t_agent_id,
              txt_device_iot: t.txt_device_iot
            }
          })
        }
        goPayBonus(params).then(response => {
          if (response.code == 200) {
            ElMessage.success("奖励发放成功！");
            // 接口调用成功，数据重置
            state.bonus.reward = null;
            getList();
            getBalanceAmount();
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.pageLoading = false;
        })
      }).catch(() => {
        console.log("用户取消操作")
      })
    };

    // 获取账户集余额
    const getBalanceAmount = () => {
      getQueryBalance().then(response => {
        if (response.code == 200) {
          state.bonus.balance = response.data.allAmount;
        } else {
          state.bonus.balance = 0;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(e);
      })
    };

    // 获取列表数据
    const getList = () => {
      state.table.tabelLoading = true;
      if (state.form.device_type_id == null) delete state.form.device_type_id;
      getDataByCondition(state.form).then(response => {
        if (response.code == 200) {
          state.table.tableData = response.data || [];
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.tabelLoading = false;
      })
    };

    // 获取设备类型
    const getDevices = () => {
      getDeviceType().then(response => {
        if (response.code == 200) {
          state.deviceOption = response.data;
        } else {
          state.deviceOption = [];
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(e);
      })
    };

    // 重置表单
    const reset = () => {
      state.formRef.resetFields();
      state.table.tableData = [];
      state.table.selected = [];
    }
    return {
      ...toRefs(state),
      getList,
      reset,
      getDevices,
      getBalanceAmount,
      sendBonus,
      rewardInput,
      numberInput,
      handleSelectionChange
    }
  }
}
</script>

<style lang="scss" scoped>
.to-be-incentive-manage {
  height: 100%;

  .tbim-form {
    .el-input {
      width: 100px;
    }

    .special-item {
      /deep/ .el-form-item__content {
        align-items: flex-start;
      }

      .el-input {
        margin-right: 4px;
      }
    }
  }

  .el-table {
    height: calc(100% - 120px);
  }

  .tbim-bottom-hint-parts {
    float: right;
    margin-top: 8px;

    .tbimbhp-bottom {
      margin-top: 8px;

      .el-input {
        width: 80px;
        margin: 0 6px;
      }

      .el-button {
        margin-left: 8px;
      }
    }
  }
}
</style>
