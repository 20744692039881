<template>
<div class="issued-incentive-manage">
  <el-form :model="form" class="row-center-start inm-form" ref="formRef">
    <el-form-item label="业务员姓名" prop="agentname">
      <el-input v-model.trim="form.agentname"></el-input>
    </el-form-item>
    <el-form-item label="发放金额范围" class="special-item" prop="rewardsfrom">
      <el-input v-model.trim="form.rewardsfrom" @input="handleInput($event, 'rewardsfrom')"></el-input>
      至
      <el-input :min="rule.rewardsto" v-model.trim="form.rewardsto" @input="handleInput($event, 'rewardsto')"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button :disabled="searchBtnDisabled" type="primary" @click="getList()">搜索</el-button>
      <el-button type="info" @click="reset()">重置</el-button>
    </el-form-item>
  </el-form>
  <el-table :data="table.tableData" v-loading="table.tabelLoading">
    <el-table-column prop="txt_province" label="省份" align="center" />
    <el-table-column prop="txt_city" label="城市" align="center" />
    <el-table-column prop="txt_salesman" label="业务员姓名" align="center" />
    <el-table-column prop="dec_cashback_amount" label="发放奖励金额" align="center" />
    <el-table-column prop="txt_cashback_reason" label="发放方案" align="center" width="300" />
    <el-table-column prop="dt_cashback" label="发放时间" align="center" />
  </el-table>
</div>
</template>

<script>
// 已发放-奖励-组件
import { reactive, toRefs, ref, computed } from 'vue';
import { ElMessage, FormInstance } from 'element-plus';
import { getBonusPaidList } from "api/apis.js";
export default {
  setup() {
    const state = reactive({
      formRef: ref(FormInstance),
      form: {
        agentname: null,
        rewardsfrom: null,
        rewardsto: null
      },
      rule: {
        rewardsto: computed(() => state.form.rewardsfrom || 0)
      },
      table: {
        tableData: [],
        tabelLoading: false
      },
      // 搜索按钮可用条件 判断
      searchBtnDisabled: computed(() => {
        let result = true,
          arr = Object.values(state.form);
        if (arr.findIndex(t => (t || "") == "") == -1) {
          result = false;
        }
        return result;
      })
    });

    // 输入框金额校验
    const handleInput = (e, key) => {
      // 清除"数字"和"."以外的字符
      e = e.replace(/[^\d.]/g, "");
      // 验证第一个字符是数字
      e = e.replace(/^\./g, "");
      // 只保留第一个, 清除多余的
      e = e.replace(/\.{2,}/g, ".");
      e = e.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      e = e.replace(/^(0{1,})(\d{1,}.*)/, '$2'); // 去除开头的多余的0
      state.form[key] = e;
      // if (key == "rewardsto" && e < state.form.rewardsfrom) {
      //   state.form[key] = null;
      //   ElMessage.warning("输入的查询金额范围不合法，请重新输入")
      // } else {
      //   state.form[key] = e;
      // }
      // if (key != "rewardsto") state.form[key] = e;
    };

    // 获取列表数据
    const getList = () => {
      if (parseFloat(state.form.rewardsto) < parseFloat(state.form.rewardsfrom)) {
        state.form.rewardsto = null;
        ElMessage.warning("输入的查询金额范围不合法，请重新输入");
      } else {
        getBonusPaidList(state.form).then(response => {
          if (response.code == 200) {
            state.table.tableData = response.data || [];
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.tabelLoading = false;
        })
      }
    };

    // 重置表单
    const reset = () => {
      state.form = {
        agentname: null,
        rewardsfrom: null,
        rewardsto: null
      }
      state.table.tableData = [];
    }
    return {
      ...toRefs(state),
      getList,
      reset,
      handleInput
    }
  }
}
</script>

<style lang="scss" scoped>
.issued-incentive-manage {
  height: 100%;

  .inm-form {
    .special-item {
      /deep/ .el-form-item__content {
        align-items: flex-start;
      }

      .el-input {
        width: 100px;
        margin: 0 4px;
      }
    }
  }
}
</style>
