<template>
<div id="page" class="incentive-manage-index">
  <el-tabs type="border-card" class="imi-tabs" v-model="activedTab">
    <el-tab-pane name="toBe" label="奖励发放">
      <to-be />
    </el-tab-pane>
    <el-tab-pane name="issued" label="已发放">
      <issued />
    </el-tab-pane>
  </el-tabs>
</div>
</template>

<script>
// 奖励发放/已发放
import { reactive, toRefs } from 'vue';
import ToBe from "./comps/toBe.vue";
import Issued from "./comps/issued.vue";
export default {
  components: {
    ToBe,
    Issued
  },
  setup() {
    const state = reactive({
      activedTab: "toBe"
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped>
.imi-tabs {
  height: 100%;
  box-sizing: border-box;

  /deep/ .el-tabs__content {
    box-sizing: border-box;
    height: calc(100% - 40px);

    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
